@mixin nav-short {
  .nav.short {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    a {
      width: 50%;
      display: flex;
      align-items: center;

      transition: background-color 250ms;

      padding: 3em 0;

      &:hover {
        background-color: #ececec;
      }

      div {
        display: grid;
        margin: 0 0.25em;

        div {
          display: flex;
          flex-direction: row;
          margin: 0;
        }

        .numeral, .nav-title {
          font-size: 2.5em;
        }

        .nav-title {
          text-transform: capitalize;
          margin-left: 5px;
        }

        .nav-blurb {
          font-size: 1.5em;
          font-style: italic;
          color: rgb(170, 170, 170);
        }

        .nav-title, .nav-blurb {
          display: none;
        }
      }
    }

    a:nth-child(1) {
      justify-content: flex-start;

      svg {
        margin-right: 2em;
      }

      div {
        justify-items: flex-start;

        * {
          text-align: start;
        }
      }
    }

    a:nth-child(2) {
      justify-content: flex-end;

      svg {
        margin-left: 2em;
      }

      div {
        justify-items: flex-end;

        * {
          text-align: end;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet-width) {
  .nav-title {
    display: initial !important;
  }

}

@media screen and (min-width: $laptop-width) {
  .nav-blurb {
    display: initial !important;
  }
}
