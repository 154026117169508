@import './article-container.scss';
@import './nav-full.scss';
@import './nav-short.scss';
@import './footer.scss';

.article-cover {
  width: 100%;
  height: 250px;

  img {
    position: relative;
    z-index: 1;

    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  div {
    position: relative;
    z-index: 2;
    top: -75px;
    height: 75px;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }
}

.divider {
  width: 100%;
  margin: 2em 0;
  border-bottom: 1px solid black;
}

#page-container {
  color: rgb(68, 68, 68);
  width: 95%;
  max-width: 1400px;
  margin: auto;

  position: relative;
  top: -$navHeight;

  @include article-container;
  @include nav-short;
}

.highchart {
  background: url(https://s3.amazonaws.com/wapopartners.com/dbknews-wp/wp-content/uploads/2017/05/09213012/Background.png) no-repeat center center;
  background-size: cover;
  width: 100%;
  max-width: 500px;
  height: 350px;
}
