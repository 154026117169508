.footer {
  h1 {
    font-family: 'Playfair Display';
    font-weight: normal;
    font-size: 3em;
  }

  p {
    font-size: 1.5em;
    font-family: 'Lora', serif;
    color: rgb(99, 99, 99);
    line-height: 1.7em;

    &.notice {
      background-color: #ececec;
      color: #444;
      font-size: 1.7em;
      padding: 0.5em 0.75em;

      &.donate {
        width: fit-content;
        padding: 1em 2em;
        margin: 2em auto;
        font-size: 1.25em;
        text-transform: uppercase;
        text-align: center;
      }

      a {
        text-decoration: underline;
      }
    }
  }

  .about, .credits {
    width: 95%;
    margin: 0 auto;
  }

  .credits {
    font-family: 'Lora';

    .sub-credit {
      margin: 1em 0;

      p {
        font-size: 1.5em;
        margin: 0.25em 0;
      }

      :first-child {
        font-weight: bold;
      }
    }

    .split {
      display: grid;
      grid-template-columns: 50% 50%;

      margin: 1em 0;

      .sub-credit {
        margin: 0;
      }
    }
  }
}

@media screen and (min-width: $laptop-width) {
  .footer .wide-split {
    display: flex;
    flex-direction: row;

    .divider {
      width: 1px;
      margin: 0 2em;
      border-bottom: none;
      border-left: 1px solid;
    }
  }
}
